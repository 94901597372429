export enum SubscriptionFrequency {
  MONTHLY = 'monthly',
  ANNUAL = 'annual'
}

export enum SubscriptionType {
  INDIVIDUAL = 'individual',
  SCHOOL = 'school'
}

export enum Plan {
  INDIVIDUAL_MONTHLY_2020 = 'individual-monthly-2020',
  INDIVIDUAL_ANNUAL_2020 = 'individual-annual-2020',
  INDIVIDUAL_MONTHLY_2022 = 'individual-monthly-2022',
  INDIVIDUAL_ANNUAL_2022 = 'individual-annual-2022',
  INDIVIDUAL_MONTHLY_2025 = 'individual-monthly-2025',
  INDIVIDUAL_ANNUAL_2025 = 'individual-annual-2025',
  SCHOOL_ANNUAL_2022 = 'school-annual-2022',
  SCHOOL_ANNUAL_2025 = 'school-annual-2025'
}

export enum ManualInvoiceAdminFee {
  SCHOOL_ANNUAL_2025 = 'school-annual-admin-fee-2025'
}
